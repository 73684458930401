<template>
  <router-view/>
</template>

<script>
</script>

<style lang="scss">


:root {
    --honey0: #FFC547;
    --honey1: #FDAD00;
    --honey2: #CC8B00;

    --sunglow0: #FFDA47;
    --sunglow1: #FFCC00;
    --sunglow2: #CCA300;

    --champagne0: #FFFBEB;
    --champagne1: #FFEEAA;
    --champagne2: #FFE270;

    --orange0: #FF9147;
    --orange1: #FF6600;
    --orange2: #CC5200;
    --orange3: #803300;
    --orange4: #522100;

    --purple0: #A30083;
    --purple1: #640050;

    --brown0: #B66454;
    --brown1: #8D4A3D;
    --brown2: #64342B;

    --white0: #FFFFFF;
    --white1: #ECECEC;
    --white2: #D6D6D6;

    --black0: #333333;
    --black1: #1F1F1F;
    --black2: #050505;

    --red0: #EA5D5D;
    --red1: #E22222;
    --red2: #B51717;

    --green0: #79B159;
    --green1: #5C8B41;
    --green2: #41612E;
}

h1 {

}

h2 {
  font-size: 130%;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: var(--black1);
}

body {
    margin: 0;
  background-color: black;
}

body * {
    font-family: 'Chakra Petch', sans-serif;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: black;;

    &.router-link-exact-active {
      color: blue;
    }
  }
}

.leaflet-div-icon {
    background: none !important;
    border: none !important;
}

.cluster {
    box-sizing: border-box;
    background-color: #8a2f2f;
    color: white;
    text-align: center;
    line-height: 40px;
    border: none;
    font-size: 16px;
    font-weight: bold;
    border-radius: 40px;
    offset-anchor: center;
    width: 40px;
    height: 40px;
    transform: translate(-20px, -20px);
}
</style>
