<template>
    <input :type="type" :style="{width: this.width + '%'}" :value="this.value">
</template>

<script>
export default {
    name: "BtnValidation",
    props: {
        value: {
          type: String,
          required: true,
        },
        type: {
            type: String,
            required: true
        },
        width: {
            type: String,
            required: false,
            default: "100"
        }
    }
}
</script>

<style scoped>
    input {
        background-color: var(--green0);
        color: var(--white0);
        border: none;
        border-radius: 10px;
        cursor: pointer;
        font-weight: bold;
        height: 2em;
        font-size: 1.5em;
        margin: 1em auto .5em;
      text-transform: uppercase;
    }

    input:active {
        background-color: var(--green1);
    }
</style>