import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toaster from "@meforma/vue-toaster";

export function easteregg(nom){
    if (nom === 'Mathilde'){
        alert('Did you mean "Petit calibre`"? ')
    }
}

createApp(App).use(store).use(router).use(Toaster).mount('#app')
