import { createStore } from 'vuex'
import axios from "axios";
import {createToaster} from "@meforma/vue-toaster";
import router from "@/router";

axios.defaults.baseURL = "https://api-mars.divtec.ch/api"

export default createStore({
  state: {
    user: null,
  },


  getters: {
    isLogged: state => !!state.user
  },


  mutations: {
    setUserData (state, userData) {
      state.user = userData
      localStorage.setItem('user', JSON.stringify(userData))
      axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`
    },

    clearUserData () {
      localStorage.removeItem('user')
      location.reload()
    }
  },


  actions: {
    login ({commit}, credentials) {

      const toaster = createToaster({ position: "bottom" });

      return axios
          .post("/login", {
              email: credentials.email,
              password: credentials.password
          })
          .then(({data}) => {
              //toaster.success(`Bienvenue ${data.user.name} !`);
              router.push('/')
              commit('setUserData', data)
          })
          .catch(err => {
            if (err.response.status === 401) {
              toaster.error("Adresse mail ou mot de passe invalide !");
            }
          })

    },

    logout({commit}) {
      commit('clearUserData')
    },


    resetSend({commit}, mail) {

      return axios
          .post('/reset/send', {
            email: mail,
          })
          .then(response => {
            console.log(response.data.message)
            //redirigert vers la page de login
          })
          .catch(error => {
            console.log(error.response.data.message)
            //notifier l'erreur
          })
    }
  },


  modules: {
  }
})
