<template>
  <div class="bg_image_container">
    <div class="bg_image"></div>
  </div>
  <div class="login">
    <div class="login-form" >
      <h2> Veuillez choisir votre activité </h2>
      <form>
        <ul>
          <li><!--@click=""-->
            <BtnValidation v-for="item in autorisations" type="button"  @click="this.validateactivity(item.id, item.nom)" width="60"  :value=item.nom></BtnValidation>
          </li>
        </ul>
      </form>
    </div>
  </div>
</template>

<script>
import BtnValidation from "@/components/Buttons/BtnValidation";
import router from "@/router";
//import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Swal from "sweetalert2";

export default {
  name: 'LoginMain',
  components: {
    BtnValidation,
  },
  data() {
    return {
      autorisations: []
    }
  },
  methods: {

    validateactivity(idActivite, nomActivitee){
      localStorage.idActivitee = idActivite
      localStorage.nomActivitee = nomActivitee
      router.push("activity")
    },

    getAutorisation() {
      let user = JSON.parse(localStorage.user)
      let axios = require('axios');
      let data = '';

      let config = {
        method: 'get',
        url: `https://api-mars.divtec.ch/api/users/${user.user.id}`,
        headers: {
          'Authorization': `Bearer ${user.access_token}`
        },
        data : data
      };

      let that = this

      axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data["responsable"]));
            localStorage.Responsable = JSON.stringify(response.data["responsable"])
            that.autorisations = response.data["responsable"]
            if (response.data["responsable"].length === 0) {
              router.push({name: 'login'})
              Swal.fire({
                icon: 'error',
                title: 'Utilisateur non autorisé',
                background: "url()",
                color: '#efefef',
                backdrop:
                    'rgba(0,0,0,0.7)',
                confirmButtonColor: 'ea5d5d',
                allowEnterKey: true,
                text: 'Vous n\'etes pas autorisé à etre la,'
              })
            }if (response.data["responsable"].length === 1){
              //that.validateactivity(response.data["responsable"][0].id, response.data["responsable"][1].nom)
              that.validateactivity(response.data["responsable"][0].id, response.data["responsable"][0].nom)
            }


                })
          .catch(function (error) {
            console.log(error);
          });


    },
    logout() {
      this.$store.dispatch("logout")
    }
  },
  computed: {
    userdata() {
      return JSON.parse(localStorage.getItem('user'))
    }
  },
  beforeMount() {
  this.getAutorisation()
  }

};

</script>

<style scoped>

.bg_image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../../../src/assets/login/login-background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  filter: blur(4px);
}

.login {
  font-family: Calibri, Helvetica, sans-serif;
  height: 100vh;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login h2 {
  font-size: 2.5em;
  margin: .25em 0;
  font-weight: bold;
  color: var(--white0);
}

.logo-container img {
  width: 150px;
  height: 150px;
  margin-top: 50px;
}

.login-form {
  padding: 1em;
  margin-top: 1em;
  border-radius: 15px;
  position: relative;
  background: transparent;
}

.login-form ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.login-form ul li {
  display: flex;
  flex-direction: column;
}

.login-form label {
  display: inline-block;
  text-align: left;
  margin-bottom: 5px;
  color: var(--white0);
  font-weight: bold;
}

.login-form input[type ="email"], .login-form input[type ="password"] {
  background: var(--white0);
  border: none;
  padding: .75em .5em;
  border-radius: 10px;
  margin-bottom: 1.75em;
}

.login-form input:focus {
  outline: 2px solid var(--purple0);
}

.login-form .btn-login {
  font-weight: bold;
  height: 1.5em;
  font-size: 1.5em;
  margin: 0 auto;
}

fieldset {
  border: 3px solid var(--white0);
  border-radius: 3px;
}

fieldset legend {
  color: var(--white0);
}

.login-form a {
  margin-top: 1em;
  color: var(--white2);
}

select {
  width: 200px;
  padding: 1em;
  margin: 20px auto 20px auto;
  border: solid 3px var(--white1);
  background: var(--white1);
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 110%;
}

select:hover {
  background: var(--white0);
  cursor: pointer;
}

li label {
  margin: 20px auto 10px auto;
}
</style>