<template>
  <div class="home">
<HeaderHome></HeaderHome>
    <BodyHome/>
  </div>
</template>

<script>
import BodyHome from '@/components/pannel/main.vue'
import HeaderHome from "@/components/pannel/HeaderHome";

export default {
  name: 'MainView',
  components: {
    BodyHome,
    HeaderHome
  },
  methods: {
  },
}
</script>

<style scoped>
.user-infos {
  z-index: 100;
  position: absolute;
  bottom: 2em;
  right: 2em;
}

.user-infos span {
  margin-right: 1em;
}

</style>
