<template>
  <div class="black">
    <div class="header">
      <img src="../../assets/Logo.png" alt="pas du tout le logo google earth en orange">
      <span @click="popUpLogOut" class="material-icons">logout</span>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import Swal from "sweetalert2";

export default {
  name: "HeaderHome",

  methods: {
    popUpLogOut() {
      let user = JSON.parse(localStorage.user)
      Swal.fire({
        title: 'Deconnexion',
        html: `
<p>${(user.user.username)}, voulez-vous vous deconnecter? </p>
`,
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Se deconnecter",
        cancelButtonText: "annuler"
      }).then((result) => {
        if (result.isConfirmed) {
          router.push({name: 'login'})
        }
      })
    },
  },
}

</script>

<style scoped>

.black {
  background-color: #050505;
}

.header{
  margin: auto;
}
.header img {
  width: 40px;
  height: 40px;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: solid 2px #FF6600;
}

.header span {
  color: var(--white1);
  font-size: 40px;
}

.header span:hover {
  cursor: pointer;
  color: var(--white0);
  text-shadow: var(--white0) 1px 0 10px;
}

</style>